import { Container } from "./styles";
import githubIcon from "../../assets/github.svg";
import DownloadApp from "../../assets/download.png";
import externalLink from "../../assets/external-link.svg";
import ScrollAnimation from "react-animate-on-scroll";

export function Project() {
  return (
    <Container id="project">
      <h2>My Projects</h2>
      <div className="projects">
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <a
                href="https://github.com/KirtiKamal/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={githubIcon} alt="Visit site" />
              </a>
              {/* <div>
                <img width={100} src="https://ojaiconcertseries.com/wp-content/uploads/2021/04/Spotify.jpg" alt="adfasf" />
              </div> */}
              <div className="project-links">
                <a
                  href="https://ai-interview-xi.vercel.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Inter-AI</h3>
              <p>
                Built Inter AI, an advanced AI interview platform that
                transforms interview preparation with custom sessions, realistic
                simulations, and comprehensive feedback. Utilized a robust tech
                stack including Next.js, React.js, PostgreSQL, Neon Database,
                Drizzle ORM, Tailwind CSS, Shadcn UI, Clerk for authentication,
                and Gemini API.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Nextjs</li>
                <li>Java Script</li>
                <li>Shadcn ui</li>
                <li>PostgressSQL</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <a
                href="https://github.com/KirtiKamal/saas-ai"
                target="_blank"
                rel="noreferrer"
              >
                <img src={githubIcon} alt="Visit site" />
              </a>
              {/* <div>
                <img width={100} src="https://ojaiconcertseries.com/wp-content/uploads/2021/04/Spotify.jpg" alt="adfasf" />
              </div> */}
              <div className="project-links">
                <a
                  href="https://saas-ai-tau.vercel.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Next 14 SaaS-AI Platform</h3>
              <p>
                I am proud to present my SaaS AI Platform, a groundbreaking
                application designed to push the boundaries of technology. With
                seamless integration of Stripe for payments, Crisp for user
                chat, and advanced chat,code and image generation features using
                OpenAI's GPT-4.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Nextjs</li>
                <li>React Hooks</li>
                <li>Type Script</li>
                <li>Shadcn ui</li>
                <li>Tailwind CSS</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <a
                href="https://github.com/KirtiKamal/saas-ai"
                target="_blank"
                rel="noreferrer"
              >
                <img src={githubIcon} alt="Visit site" />
              </a>
              {/* <div>
                <img width={100} src="https://ojaiconcertseries.com/wp-content/uploads/2021/04/Spotify.jpg" alt="adfasf" />
              </div> */}
              <div className="project-links">
                <a
                  href="https://spotify-clone-kirtikamal.vercel.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Spotify Clone</h3>
              <p>
                Experience the magic of music with my Spotify Clone, crafted
                using cutting-edge technologies like Next.js, Radix UI,
                Supabase, TypeScript, Tailwind CSS, Zustand, and React Hook
                Form.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Nextjs</li>
                <li>React Hooks</li>
                <li>Type Script</li>
                <li>Radix UI</li>
                <li>Tailwind CSS</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <a
                href="https://github.com/KirtiKamal/CommunityWebsite"
                target="_blank"
                rel="noreferrer"
              >
                <img src={githubIcon} alt="Visit site" />
              </a>
              {/* <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b " stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg> */}
              <div className="project-links">
                <a
                  href="https://www.decodedevs.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Decode Devs - Community Website</h3>
              <p>
                The idea for creating this community stemmed from real-life
                experiences. We observed how many students lack guidance and
                proper mentorship for their futures, which often leads them to
                choose inappropriate career paths.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React</li>
                <li>Tailwind CSS</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <a
                href="https://github.com/KirtiKamal/kirtikamalsahoo"
                target="_blank"
                rel="noreferrer"
              >
                <img src={githubIcon} alt="Visit site" />
              </a>
              <div className="project-links">
                <a
                  href="https://kirtikamalsahoo.vercel.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Portfolio</h3>
              <p>
                This website sells home decor products and helps people decorate
                their homes. It is easy to find what you need, whether it's
                furniture or decorations. The site is useful for people who want
                to make their homes look nice and need some help.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React</li>
                <li>Tailwind CSS</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <a
                href="https://github.com/KirtiKamal/Yummy-Delights"
                target="_blank"
                rel="noreferrer"
              >
                <img src={githubIcon} alt="Visit site" />
              </a>
              <div className="project-links">
                <a
                  href="https://kirtikamal.tech/Yummy-Delights/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Yummy-Delights</h3>
              <p>
                Welcome to our Yummy Delights website! Our mission is to inspire
                and empower you to create delicious and nutritious meals in your
                own kitchen.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>HTML</li>
                <li>CSS</li>
                <li>Javascript</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <a
                href="https://github.com/KirtiKamal/Weather-App"
                target="_blank"
                rel="noreferrer"
              >
                <img src={githubIcon} alt="Visit site" />
              </a>
              <div className="project-links">
                <a
                  href="https://kirtikamal.tech/Weather-App/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Weather App </h3>
              <p>
                A user-friendly app offering real-time weather data. Stay
                informed with ease and convenience. Get accurate forecasts at
                your fingertips, for any location, anytime!
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>HTML</li>
                <li>CSS</li>
                <li>Javascript</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <a
                href="https://github.com/KirtiKamal/QR-CodeGenerator"
                target="_blank"
                rel="noreferrer"
              >
                <img src={githubIcon} alt="Visit site" />
              </a>
              <div className="project-links">
                <a
                  href="https://kirtikamal.tech/QR-CodeGenerator/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>QR-CodeGenerator</h3>
              <p>
                Easy-to-use QR Code Generator using HTML, CSS, and JS. Create
                custom QR codes for URLs, text, and contact info. Seamlessly
                share information through scannable QR codes.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>HTML</li>
                <li>CSS</li>
                <li>JavaScript</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <a
                href="https://github.com/KirtiKamal/ACM-StudentChapter"
                target="_blank"
                rel="noreferrer"
              >
                <img src={githubIcon} alt="Visit site" />
              </a>
              <div className="project-links">
                <a
                  href="https://kirtikamal.tech/ACM-StudentChapter/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Trident ACM Student Chapter</h3>
              <p>
                Discover Our Student Chapters Website: Crafted with Passion and
                Skill. Unite, Learn, and Excel with Trident ACM. Your gateway to
                a thriving tech community. Join us, together we soar!
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>HTML</li>
                <li>CSS</li>
                <li>JavaScript</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
      </div>
    </Container>
  );
}
